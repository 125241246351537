import { Box, Button,  Text } from "@chakra-ui/react";
import './home.css';

const demoUrl = "https://cogagent.aminer.cn/static/media/demo.mp4"
const urlMap = {
  "加入社群": "https://github.com/THUDM/CogAgent",
  "技术文档": "https://arxiv.org/abs/2312.08914",
  "用户文档": "https://cogagent.aminer.cn/static/agreement/%E7%94%A8%E6%88%B7%E6%89%8B%E5%86%8C.pdf",
  "意见反馈": "https://0ggfznkwh4j.typeform.com/to/WfuYTxMM?typeform-source=www.openinterpreter.com ",
  "投递简历": "https://zhipu-ai.jobs.feishu.cn/zhipucampus/position/?keywords=CogAgent",
  "隐私政策": "https://cogagent.aminer.cn/static/agreement/GLM-PC%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.pdf",
  "用户协议": "https://cogagent.aminer.cn/static/agreement/GLM-PC%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE.pdf",
  "关于我们": "https://www.zhipuai.cn/"
}

function Home() {
  return (
    <div>
      <main>
        <Text className="t1" >GLM-PC：<Text className="t1-sub" as="span">电脑智能体大模型</Text> </Text>
        <Text className="t2"  >LLM for Computer Use</Text>
        <Text className="t3"  >基于CogAgent视觉语言大模型构建的电脑智能体</Text>
      </main>
      <footer>
        <Button color="white" bg="black" _hover={{ bg: "black" }} border="1px solid" rounded="4px" size="sm" variant="outline">
          <a href="https://www.wjx.top/vm/mOs9cHw.aspx" target="_blank" rel="noreferrer">申请体验</a>
        </Button>

      </footer>
      <div className="about">
        <div><a href={urlMap.隐私政策} target="_blank" rel="noreferrer" >隐私政策</a></div>
        <div>|</div>
        <div><a href={urlMap.用户协议} target="_blank" rel="noreferrer">用户协议</a></div>
        <div>|</div>
        <div><a href={urlMap.关于我们} target="_blank" rel="noreferrer">关于我们</a></div>
      </div>

      <video className="bg-video" autoPlay muted loop playsInline>
        <source src={demoUrl} type="video/mp4" />
      </video>
    </div>
  );
}

export default Home;
