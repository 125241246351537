import { Box, Button, Flex, Text,chakra } from "@chakra-ui/react";


export default function Downloads() {
  return (
    <Box>
      <Text textAlign="center" mt="10rem" fontSize="3.75rem"><chakra.span fontSize="4.75rem" verticalAlign="-10px">GLM-PC</chakra.span>(牛牛)，为您服务</Text>
      <Text textAlign="center" fontSize="1.5rem" >立刻申请体验，认领你的第一只赛博牛牛</Text>
      <Flex justify="center" mt="2rem">
        <Button as="a" href={"https://cogagent.aminer.cn/static/pkg/GLM-PC-arm64.dmg"} rounded="full" w="200px">Mac(Apple芯片)下载</Button>
        <Button ml="1em" rounded="full" w="200px">Windows 敬请期待</Button>
      </Flex>
      <Box mt="32px" w="80%" mx="auto"  bg="white" rounded="4px" overflow="hidden">
      <video width="100%" autoPlay muted loop playsInline>
        <source src={"https://cogagent.aminer.cn/static/media/demo.mp4"} type="video/mp4" />
      </video>
      </Box>
      <Box pt="40px">

      </Box>
    </Box>
  )
}
